import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/main.css'; // Adjust the path according to your structure
import HomePage from './pages/HomePage';
import CostCalculationPage from './pages/CostCalculationPage';
import BadmintonSchedulePage from './pages/BadmintonSchedulePage';
import ScheduleCardPage from './pages/ScheduleCardPage';

const App: React.FC = () => {
  return (
    <Router>
      {/* <nav>
        <Link to="/">Home</Link>
        <Link to="/cost-calculation">Cost Calculation</Link>
        <Link to="/badminton-schedule">Badminton Schedule</Link>
      </nav> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cost-calculation" element={<CostCalculationPage />} />
        <Route path="/badminton-schedule" element={<BadmintonSchedulePage />} />
        <Route path="/schedule-card" element={<ScheduleCardPage />} />
      </Routes>
    </Router>
  );
}

export default App;
