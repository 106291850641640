import React from 'react';
import Header from './../components/Header'; 
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {


  return (
    <div id="page-container">

<div id="content-wrap">

      <Header />
    
      <Container className="mt-3 mb-3">
        <Row>
          <Col sm="4">
            <div className="mt-3">
              <Link className="btn rider-button-1" to="/cost-calculation">Cost Calculation</Link>
            </div>
          </Col>

          <Col sm="4">
            <div className="mt-3">
              <Link className="btn rider-button-2" to="/badminton-schedule">Badminton Schedule</Link>
            </div>
          </Col>

          <Col sm="4">
            <div className="mt-3">
              <Link className="btn rider-button-3" to="/schedule-card">Schedule Card</Link>
            </div>
          </Col>

        </Row>
      </Container>
 </div>
      


 <footer id="footer" className="mt-5">
            <Container>
            <Row>
              <Col>Designed by Faisel Kachappilly © {new Date().getFullYear()}. All rights reserved
                <span> |</span> 
                <a href="http://www.webzenz.ch/" target="_blank" rel="noopener noreferrer"> Webagentur</a></Col>
            </Row>
          </Container>
      </footer>

      </div>
  );
};

export default HomePage;
