import React, { useState } from 'react';
import Header from './../components/Header';
import { FaCopy } from 'react-icons/fa';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

interface Player {
  name: string;
  isAdmin: boolean;
  extraCost: number;
}




const CostCalculationPage: React.FC = () => {

  const allPlayers = ["Faisel", "Niju", "Manaf", "Thomaskutty", "Tinku", "Vijay", "Chrisjo", "Deepu", "Nibin", "Nibu", "Rohan"];
  const [resultsText, setResultsText] = useState<string>('');

  const [numCourts, setNumCourts] = useState<number>(1);
  const [courtPrice, setCourtPrice] = useState<number>(32);
  const [numShuttles, setNumShuttles] = useState<number>(5);
  const [drinksPrice, setDrinksPrice] = useState<number | null>(null);

  const [players, setPlayers] = useState<Player[]>([{ name: allPlayers[0], isAdmin: false, extraCost: 0 }]);


  const [calculationResults, setCalculationResults] = useState<{ name: string; cost: number }[]>([]);




  const handleSelectChange = (index: number, value: string) => {
    const updatedPlayers = players.map((player, i) =>
      i === index ? { ...player, name: value } : player
    );
    setPlayers(updatedPlayers);
  };

  const addPlayer = () => {
    setPlayers([...players, { name: '', isAdmin: false, extraCost: 0 }]);
  };

  const handlePlayerChange = (index: number, field: keyof Player, value: any) => {
    const updatedPlayers = players.map((player, i) =>
      i === index ? { ...player, [field]: value } : player
    );
    setPlayers(updatedPlayers);
  };


  const copyResultsToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(resultsText);
      toast("Copied to clipboard!", { autoClose: 2000 });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  


  const calculateCosts = () => {
    const noOfPlayers = players.filter(player => player.name).length;
    const eachCourtPrice = noOfPlayers > 0 ? (numCourts * courtPrice) / noOfPlayers : 0;
    const totalShuttlesPrice = numShuttles * 1.39;
    const eachShuttlesPrice = noOfPlayers > 0 ? totalShuttlesPrice / noOfPlayers : 0;
  


     // Find the first admin's name or default to 'Faisel'
    const adminName = players.find(player => player.isAdmin)?.name || 'Faisel';


    const results = players
    .filter(player => !player.isAdmin && player.name) // Exclude isAdmin players from results
    .map(player => ({
      name: player.name,
      cost: eachCourtPrice + eachShuttlesPrice + (player.extraCost || 0)
    }));

  setCalculationResults(results);

  const formattedResults = [
    `Last KALI ✌️\n`,
    `${numCourts} x Court = CHF ${courtPrice}`,
    `${numShuttles} x Hybrid Shuttle = CHF ${totalShuttlesPrice.toFixed(2)}`,
    drinksPrice !== null ? `Drinks = CHF ${drinksPrice.toFixed(2)}\n` : '',
    `TWINT to ${adminName}\n`
  ].concat(results.map(r => `${r.name} : CHF ${r.cost.toFixed(2)}`));

  setResultsText(formattedResults.join('\n'));

  };
  

  return (
    <div id="page-container">

    <div id="content-wrap">
      <Header />
      <Container className="mt-3 mb-3">
        <Row>
          <Col><h1>Cost Calculation</h1></Col>
        </Row>
      </Container>
      <ToastContainer position="top-center" />
      <Container className="mt-3 mb-5">
        <Form>
          {/* Number of courts */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Number of Courts</Form.Label>
            <Col sm="10">
              <Form.Control
                type="number"
                value={numCourts}
                onChange={(e) => setNumCourts(parseInt(e.target.value))}
                min="1"
              />
            </Col>
          </Form.Group>

          {/* Court Price */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Court Price</Form.Label>
            <Col sm="10">
              <Form.Control
                type="number"
                value={courtPrice}
                onChange={(e) => setCourtPrice(parseFloat(e.target.value))}
                min="0"
              />
            </Col>
          </Form.Group>

          {/* Number of Shuttles */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Number of Shuttles</Form.Label>
            <Col sm="10">
              <Form.Control
                type="number"
                value={numShuttles}
                onChange={(e) => setNumShuttles(parseInt(e.target.value))}
                min="0"
              />
            </Col>
          </Form.Group>


          {/* Drinks Price */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Drinks Price</Form.Label>
            <Col sm="10">
              <Form.Control
                type="number"
                value={drinksPrice !== null ? drinksPrice : ''} // To handle null value
                onChange={(e) => setDrinksPrice(e.target.value ? parseFloat(e.target.value) : null)}
                min="0"
                placeholder="Enter drinks price (optional)"
              />
            </Col>
          </Form.Group>

          <br />
          <hr />
          <br />

          {/* Dynamic player fields */}
          {players.map((player, index) => (
            <React.Fragment key={index}>
              <div className="player-block">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">Player {index + 1}</Form.Label>
                <Col sm="3">
                  <Form.Select
                    value={player.name}
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                  >
                    {allPlayers.map((pName, idx) => (
                      <option key={idx} value={pName}>{pName}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">Admin</Form.Label>
                <Col sm="5">
                  <Form.Check
                    type="checkbox"
                    checked={player.isAdmin}
                    onChange={(e) => handlePlayerChange(index, 'isAdmin', e.target.checked)}
                  />
                </Col>
              </Form.Group>


              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">Extra Cost</Form.Label>
                <Col sm="2">
                  <Form.Control
                    type="number"
                    value={player.extraCost}
                    onChange={(e) => handlePlayerChange(index, 'extraCost', parseFloat(e.target.value))}
                    min="0"
                  />
                </Col>
              </Form.Group>
              </div>
            </React.Fragment>
          ))}

          <Button variant="primary" onClick={addPlayer}>Add Player</Button>

          <br />
          <hr />
          <br />

          <Button variant="success" onClick={calculateCosts}>Calculate Costs</Button>
        </Form>

        {/* Display Results */}


        <div>
          {calculationResults.length > 0 && (
            <div>
              <br />
              <h3>Calculation Results:</h3>
              <br />

              <div className="calculation-result">
              <Row>
              <Col sm="4">
                <pre>{resultsText}</pre>
                </Col>
                </Row>
              </div>

              <Button onClick={copyResultsToClipboard}>
                <FaCopy /> Copy Results
              </Button>
            </div>
          )}
        </div>


      </Container>


      </div>
      


      <footer id="footer" className="mt-5">
            <Container>
            <Row>
              <Col>Designed by Faisel Kachappilly © {new Date().getFullYear()}. All rights reserved
                <span> |</span> 
                <a href="http://www.webzenz.ch/" target="_blank" rel="noopener noreferrer"> Webagentur</a></Col>
            </Row>
          </Container>
      </footer>


    </div>

  );
};

export default CostCalculationPage;
