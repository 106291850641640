import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { addDays, format } from 'date-fns'; // You'll need to install date-fns for date manipulation
import { FaCopy } from 'react-icons/fa'; // Ensure you have react-icons installed
import Header from './../components/Header';
import { toast, ToastContainer } from 'react-toastify';


interface ScheduleItem {
  date: Date;
  startTime: string;
  endTime: string;
}



const BadmintonSchedulePage: React.FC = () => {


  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [scheduleText, setScheduleText] = useState<string>('');
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
  const [finalSchedule, setFinalSchedule] = useState<{ text: string; copyText: string }[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);




  const generateFinalSchedule = () => {
    const scheduleLines = scheduleItems.map(item => ({
      text: `${format(item.date, 'dd.MM.yyyy EEEE')}\n${item.startTime} - ${item.endTime}`,
      copyText: `${format(item.date, 'dd.MM.yyyy EEEE')}\n${item.startTime} - ${item.endTime}`
    }));

    setFinalSchedule(scheduleLines);
  };

  // const generateSchedule = () => {
  //   let schedule = "🏸🐎 BADMINTON  🐎🏸 Who's in?\n\n🚨Limit your poll to 4 per day. If there are already 4, just message in the group and we'll consider booking a second court🚨\n\n";
  //   let start = new Date(startDate);
  //   let end = new Date(endDate);

  //   for (let day = start; day <= end; day = addDays(day, 1)) {
  //     schedule += format(day, 'dd.MM.yyyy') + '\n' + startTime + ' - ' + endTime + '\n\n';
  //   }

  //   setScheduleText(schedule);
  // };


  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast("Copied to clipboard!", { autoClose: 2000 });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };







  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const newScheduleItems = [];

      for (let day = start; day <= end; day = addDays(day, 1)) {
        newScheduleItems.push({ date: day, startTime: '18:30', endTime: '20:00' });
      }

      setScheduleItems(newScheduleItems);
    }
  }, [startDate, endDate]);

  // Function to handle changes in schedule items
  const handleScheduleItemChange = (index: number, field: keyof ScheduleItem, value: string) => {
    const updatedItems = scheduleItems.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setScheduleItems(updatedItems);
  };



  return (

    <div id="page-container">

      <div id="content-wrap">

        <Header />

        <Container className="mt-3 mb-3">
          <Row>
            <Col><h1>Badminton Schedule</h1></Col>
          </Row>
        </Container>


        <ToastContainer position="top-center" />

        <Container>
          <Form>
            {/* Date and Time Input Fields */}
            <Form.Group as={Row} className="mb-3">
              <Col>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              {/* <Col>
            <Form.Label>Start Time</Form.Label>
            <Form.Control 
              type="time" 
              value={startTime} 
              onChange={(e) => setStartTime(e.target.value)} 
            />
          </Col>
          <Col>
            <Form.Label>End Time</Form.Label>
            <Form.Control 
              type="time" 
              value={endTime} 
              onChange={(e) => setEndTime(e.target.value)} 
            />
          </Col> */}
            </Form.Group>


          </Form>


          {scheduleItems.map((item, index) => (
            <Row key={index} className="mb-3">
              <Col>
                <Form.Control
                  type="text"
                  readOnly
                  value={format(item.date, 'dd.MM.yyyy')}
                />
              </Col>
              <Col>
                <Form.Control
                  type="time"
                  value={item.startTime}
                  onChange={(e) => handleScheduleItemChange(index, 'startTime', e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="time"
                  value={item.endTime}
                  onChange={(e) => handleScheduleItemChange(index, 'endTime', e.target.value)}
                />
              </Col>

            </Row>
          ))}
          <br />
          <br />

          <Button variant="success" onClick={generateFinalSchedule}>Generate Schedule</Button>

          <br />
          <hr />
          <br />


          {/* Display final schedule with copy buttons */}
          <div>

            <div className="schedule-result">
              <Row className="mb-3 align-items-center">
                <Col>
                🏸🐎 BADMINTON  🐎🏸 Who's in? 🚨 Max 4 per day in the poll, please. If it's already 4, message in the group. If at least 2 more are ready, we'll consider a second court🚨
                </Col>
                <Col xs="auto">
                  <Button onClick={() => copyToClipboard("🏸🐎 BADMINTON  🐎🏸 Who's in?\n\n🚨 Max 4 per day in the poll, please. If it's already 4, message in the group. If at least 2 more are ready, we'll consider a second court🚨")}>
                    <FaCopy />
                  </Button>
                </Col>
              </Row>





              {finalSchedule.map((item, index) => (

                <Row key={index} className="mb-3 align-items-center">
                  <Col>
                    {item.text}
                  </Col>
                  <Col xs="auto">
                    <Button onClick={() => copyToClipboard(item.copyText)}>
                      <FaCopy />
                    </Button>
                  </Col>
                </Row>



              ))}
            </div>
          </div>



        </Container>
      </div>


      <footer id="footer" className="mt-5">
        <Container>
          <Row>
            <Col>Designed by Faisel Kachappilly © {new Date().getFullYear()}. All rights reserved
              <span> |</span>
              <a href="http://www.webzenz.ch/" target="_blank" rel="noopener noreferrer"> Webagentur</a></Col>
          </Row>
        </Container>
      </footer>


    </div>
  );
};

export default BadmintonSchedulePage;
