import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import Header from './../components/Header';

const ScheduleCardPage: React.FC = () => {
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [color, setColor] = useState('#f84a05');
  const [gameName, setGameName] = useState('BADMINTON');
  const [timeStart, setTimeStart] = useState('18:30');
  const [timeEnd, setTimeEnd] = useState('20:00');
  const [gameColor, setGameColor] = useState('#f98011');
  const [courtNumber, setCourtNumber] = useState('3');
  const [sportCenterName, setSportCenterName] = useState('Grünfeld Sportcenter');
  const [sportCenterAddress, setSportCenterAddress] = useState('Grünfeldstrasse 20, 8645 Rapperswil-Jona');
  const formattedDateWithDay = format(new Date(date), 'EEEE');
  const [imageURL, setImageURL] = useState<string | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleGenerateImage = async () => {
    if (cardRef.current) {
      const canvas = await html2canvas(cardRef.current);
      const imageData = canvas.toDataURL('image/jpeg');
      setImageURL(imageData);
    }
  };

  const handleDownloadImage = () => {
    if (imageURL) {
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `schedule-card-${date}.jpg`;
      link.click();
    }
  };

  // Convert the provided date string to a readable format
  const formattedDate = format(new Date(date), 'dd.MM.yyyy');





  return (
    <div id="page-container">
      <div id="content-wrap">

      <Header />
        <Container className="mt-3 mb-3">
          <Row>
            <Col><h1>Schedule Card</h1></Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col>

              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">Date</Form.Label>
                  <Col sm="10">
                    <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">Date Color</Form.Label>
                  <Col sm="10">
                    <Form.Control type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                  </Col>
                </Form.Group>




                {/* Game Name */}
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Label htmlFor="gameName">Game Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="gameName"
                      value={gameName}
                      onChange={(e) => setGameName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                {/* Time Start */}
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Label htmlFor="timeStart">Time Start</Form.Label>
                    <Form.Control
                      type="time"
                      id="timeStart"
                      value={timeStart}
                      onChange={(e) => setTimeStart(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                {/* Time End */}
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Label htmlFor="timeEnd">Time End</Form.Label>
                    <Form.Control
                      type="time"
                      id="timeEnd"
                      value={timeEnd}
                      onChange={(e) => setTimeEnd(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">Game Text Color</Form.Label>
                  <Col sm="10">
                    <Form.Control type="color" value={gameColor} onChange={(e) => setGameColor(e.target.value)} />
                  </Col>
                </Form.Group>




                {/* Court Number */}
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2" htmlFor="courtNumber">Court Number</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="number"
                      id="courtNumber"
                      value={courtNumber}
                      onChange={(e) => setCourtNumber(e.target.value)} // Set as a string
                    />
                  </Col>
                </Form.Group>
                {/* Sport Center Name */}
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Label htmlFor="sportCenterName">Sport Center Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="sportCenterName"
                      value={sportCenterName}
                      onChange={(e) => setSportCenterName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                {/* Sport Center Address */}
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Label htmlFor="sportCenterAddress">Sport Center Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      id="sportCenterAddress"
                      value={sportCenterAddress}
                      onChange={(e) => setSportCenterAddress(e.target.value)}
                    />
                  </Col>
                </Form.Group>


                <Row>
                  <Col>
                  <h1>Preview</h1>
                    <div
                        id="overlay-content"
                        className="mt-3"
                        ref={cardRef}
                        style={{
                          position: 'relative',
                          width: '800px', // Set the width of your template image
                          height: '534px', // Set the height of your template image
                          backgroundImage: `url(/assets/badminton_base_template.jpg)`,
                          backgroundSize: 'cover',
                          color: 'white', // Assuming your text is white
                          padding: '20px',
                        }}
                      >
                      {/* Overlay Content */}
                      <div className="overlay">

                        <div className="overlay-text-1" style={{ color: color, fontFamily: 'ComicSansMS' }}>{formattedDate}</div>
                        <div className="overlay-text-2" style={{ color: color, textTransform: 'uppercase', fontFamily: 'Akrobat' }}>{formattedDateWithDay}</div>

                        <div className="overlay-text-3" style={{ color: gameColor, fontFamily: 'Akrobat' }}>{gameName}</div>
                        <div className="overlay-text-4" style={{ color: gameColor, fontFamily: 'ComicSansMS' }}>{timeStart} - {timeEnd}</div>


                        <div className="overlay-text-5" style={{ color: '#ffffff', fontFamily: 'ComicSansMS' }}>Court {courtNumber}</div>
                        <div className="overlay-text-6" style={{ color: '#ffffff', fontFamily: 'ComicSansMS' }}>{sportCenterName}</div>
                        <div className="overlay-text-7" style={{ color: '#ffffff', fontFamily: 'ComicSansMS' }}>{sportCenterAddress}</div>
                      </div>

                    </div>
                  </Col>
                </Row>

                  


                <div className="button-block">
                  <Button className="generate-button" variant="primary" onClick={handleGenerateImage}>
                    Generate Card
                  </Button>
                  {imageURL && (
                  <>
                    <Button className="download-button" variant="success" onClick={handleDownloadImage}>
                      Download Card
                    </Button>
                  </>
                )}
                </div>


                <div className="preview-block">
                {imageURL && (
                  <>
                    <img className="preview-image" src={imageURL} alt="Schedule Card Preview" style={{ margin: '20px 0' }} />
                  </>
                )}

              </div>

              </Form>
            </Col>
          </Row>










        </Container>

      </div>
      <footer id="footer" className="mt-5">
        <Container>
          <Row>
            <Col>Designed by Faisel Kachappilly © {new Date().getFullYear()}. All rights reserved
              <span> |</span>
              <a href="http://www.webzenz.ch/" target="_blank" rel="noopener noreferrer"> Webagentur</a></Col>
          </Row>
        </Container>
      </footer>
    </div>


  );
};

export default ScheduleCardPage;
