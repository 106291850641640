// Header.tsx
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <Navbar bg="primary" variant="dark">
      <Container>
        <Navbar.Brand href="/">
            <img className="badminton-logo" src="/assets/cock_riders_logo.png" alt="Logo" />
        </Navbar.Brand>
        <Nav className="me-auto">
          {/* <Nav.Item>
            <CustomNavLink to="/" label="Home" />
          </Nav.Item> */}
          <Nav.Item>
            <CustomNavLink to="/cost-calculation" label="Cost" />
          </Nav.Item>
          <Nav.Item>
            <CustomNavLink to="/badminton-schedule" label="Schedule" />
          </Nav.Item>
          <Nav.Item>
            <CustomNavLink to="/schedule-card" label="Card" />
          </Nav.Item>

        </Nav>
      </Container>
    </Navbar>
  );
};

const CustomNavLink = ({ to, label }: { to: string, label: string }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink 
      to={to}
      className={match ? "nav-link active" : "nav-link"}
    >
      {label}
    </NavLink>
  );
};

export default Header;
